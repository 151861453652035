import { CATEGORY_LIST_FAILED, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS } from "./CategoryListTypes";

export function categoryListResponse(state = {}, action: any) {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { ...action.payload };
        case CATEGORY_LIST_SUCCESS:
            return { ...action.payload };
        case CATEGORY_LIST_FAILED:
            return { ...action.payload };
        default:
            return state;
    }
}