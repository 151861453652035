import { GET_CMS_BLOCK_FAILED, GET_CMS_BLOCK_REQUEST, GET_CMS_BLOCK_SUCCESS, GET_CONTACTUS_BLOCK_FAILED, GET_CONTACTUS_BLOCK_REQUEST, GET_CONTACTUS_BLOCK_SUCCESS } from "./CMSBlockTypes";

export function cmsBlockResponse(state = {}, action: any) {
    switch (action.type) {
        case GET_CMS_BLOCK_REQUEST:
            return { ...action.payload };
        case GET_CMS_BLOCK_SUCCESS:
            return { ...action.payload };
        case GET_CMS_BLOCK_FAILED:
            return { ...action.payload };
        case GET_CONTACTUS_BLOCK_REQUEST:
            return { ...action.payload };
        case GET_CONTACTUS_BLOCK_SUCCESS:
            return { ...action.payload };
        case GET_CONTACTUS_BLOCK_FAILED:
            return { ...action.payload };
        default:
            return state;
    }
}