export const URL_RESOLVER_REQUEST = "URL_RESOLVER_REQUEST";
export const URL_RESOLVER_SUCCESS = "URL_RESOLVER_SUCCESS";
export const URL_RESOLVER_FAILED = "URL_RESOLVER_FAILED";

export const CONTACT_US_FORM_REQUEST = "CONTACT_US_FORM_REQUEST";
export const CONTACT_US_FORM_SUCCESS = "CONTACT_US_FORM_SUCCESS";
export const CONTACT_US_FORM_FAILED = "CONTACT_US_FORM_FAILED";

export const STORE_CONFIG_REQUEST = "STORE_CONFIG_REQUEST";
export const STORE_CONFIG_SUCCESS = "STORE_CONFIG_SUCCESS";
export const STORE_CONFIG_FAILED = "STORE_CONFIG_FAILED";

export const GUEST_NEWS_LETTER_SUCCESS = "GUEST_NEWS_LETTER_SUCCESS";

export const SEARCH_FUNCTIONALITY_SUCCESS = "SEARCH_FUNCTIONALITY_SUCCESS";
export const SEARCH_FUNCTIONALITY_ERROR = "SEARCH_FUNCTIONALITY_ERROR";

export const MY_ORDERS_REQUEST = "MY_ORDERS_REQUEST";
export const MY_ORDERS_SUCCESS = "MY_ORDERS_SUCCESS";
export const MY_ORDERS_FAILED = "MY_ORDERS_FAILED";

export const MY_ORDERS_DETAILS_REQUEST = "MY_ORDERS_DETAILS_REQUEST";
export const MY_ORDERS_DETAILS_SUCCESS = "MY_ORDERS_DETAILS_SUCCESS";
export const MY_ORDERS_DETAILS_FAILED = "MY_ORDERS_DETAILS_FAILED";

export const GET_CUSTOM_FORM_SUCCESS = "GET_CUSTOM_FORM_SUCCESS";

export const SUBMIT_SAMPLE_FORM_SUCCESS = "SUBMIT_SAMPLE_FORM_SUCCESS";
export const SUBMIT_SAMPLE_FORM_ERROR = "SUBMIT_SAMPLE_FORM_ERROR";

export const GET_META_TITLE_SUCCESS = "GET_META_TITLE_SUCCESS";

export const GET_SOFT_SEARCH_KEY_REQUEST = "GET_SOFT_SEARCH_KEY_REQUEST";
export const GET_SOFT_SEARCH_KEY_SUCCESS = "GET_SOFT_SEARCH_KEY_SUCCESS";
export const GET_SOFT_SEARCH_KEY_FAILED = "GET_SOFT_SEARCH_KEY_FAILED";

export const GET_MIN_INSTALLMENT_AMOUNT_REQUEST = "GET_MIN_INSTALLMENT_AMOUNT_REQUEST";
export const GET_MIN_INSTALLMENT_AMOUNT_SUCCESS = "GET_MIN_INSTALLMENT_AMOUNT_SUCCESS";
export const GET_MIN_INSTALLMENT_AMOUNT_FAILED = "GET_MIN_INSTALLMENT_AMOUNT_FAILED";


export const GET_PRODUCT_INSTALLMENT_HTML_REQUEST = "GET_PRODUCT_INSTALLMENT_HTML_REQUEST";
export const GET_PRODUCT_INSTALLMENT_HTML_SUCCESS = "GET_PRODUCT_INSTALLMENT_HTML_SUCCESS";
export const GET_PRODUCT_INSTALLMENT_HTML_FAILED = "GET_PRODUCT_INSTALLMENT_HTML_FAILED";
