import {
  GRAPHQL_HOMEPAGE_API_FAILED,
  GRAPHQL_HOMEPAGE_API_REQUEST,
  GRAPHQL_HOMEPAGE_API_SUCCESS,
  HEADER_BANNER_CMS_BLOCK_FAILED,
  HEADER_BANNER_CMS_BLOCK_SUCCESS,
} from "./HomepageTypes";

export function homepageData(state = {}, action: any) {
  switch (action.type) {
    case GRAPHQL_HOMEPAGE_API_REQUEST:
      return { ...action.payload };
    case GRAPHQL_HOMEPAGE_API_SUCCESS:
      return { ...action.payload };
    case GRAPHQL_HOMEPAGE_API_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export const headerBannerReducer = (state = {}, action: any) => {
  switch (action.type) {
    case HEADER_BANNER_CMS_BLOCK_SUCCESS:
      return { ...action.payload };
    case HEADER_BANNER_CMS_BLOCK_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
};
