import { createStore, applyMiddleware, compose } from "redux";
import indexReducer from "./Index";
import ReduxThunk from "redux-thunk";
import { persistStore } from "redux-persist";

export const store = createStore(
  indexReducer,
  compose(applyMiddleware(ReduxThunk))
);

export const persistor = persistStore(store);
