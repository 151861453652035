import { ORDERS_SUCCESS_FAILED, ORDERS_SUCCESS_REQUEST, ORDERS_SUCCESS_SUCCESS } from "./OrdersTypes";

export function ordersSuccessResponse(state = {}, action: any) {
    switch (action.type) {
        case ORDERS_SUCCESS_REQUEST:
            return { ...action.payload };
        case ORDERS_SUCCESS_SUCCESS:
            return { ...action.payload };
        case ORDERS_SUCCESS_FAILED:
            return { ...action.payload };
        default:
            return state;
    }
}