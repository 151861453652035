import axios from "axios";
import { KEY_USER_TOKEN } from "../Constants/Labels";
import { CART_ID, GUEST_EMAIL } from "../Constants/LocalStorage";
import { getSessionItem } from "../Utility/Utilities";
import { AxiosPromise } from "axios";

export const createLogs = async ({ action, payment_method , api_response , api_request , amount  , status}: any) => {
    try {
      const cartID = getSessionItem(CART_ID);
      const email = getSessionItem(GUEST_EMAIL);
      const userToken = getSessionItem(KEY_USER_TOKEN);
  
      let logData : any = {
        cart_id:cartID,
        email:email,
        customer_token:userToken,
        action,
        payment_method,
        api_response,
        api_request,
        amount : amount ?? null,
        type:"Salon",
        status:status ?? "info"
      }
      logData = JSON.stringify(logData);
      await axios.post("/logs", { level : "info", message:logData });
    } catch (error) {
      console.log("error", error);
    }
  };

  export const getMinInstallmentAmount = (
    price: string,
    tooltipType: string = "product"
  ) => {
    const myHeaders = new Headers();
    const formdata = new FormData();
    formdata.append("price", price);
    formdata.append("tooltipType", tooltipType);
    formdata.append("storeid", "2");
    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}/dbconfig/index/getmininstalmentamount/`,
      requestOptions
    );
  };
  
  export const getSoftSearchSplashKey = (price: string) => {
    return axios.post(
      `${process.env.REACT_APP_DIVIDEBUY_API_URL}/get-softsearch-key`,
      {
        retailer_base_url: `${process.env.REACT_APP_API_URL}`,
        order_total: price,
      }
    );
  };
  
  export const getDividebuyProductInstallmentHtml = (
    price: string,
    tooltipType: string = "product"
  ) => {
    const myHeaders = new Headers();
    const formdata = new FormData();
    formdata.append("price", price);
    formdata.append("tooltipType", tooltipType);
    formdata.append("storeid", "2");
    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}/dbproduct/index/displayproducttooltip/`,
      requestOptions
    );
  };
  
  export const verifySplashKey = (splashKey : string) : AxiosPromise<any>  => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/dividebuygraphqlcustom/payment/response/splashKey/${splashKey}`,
      {}
    );
  }
  