export const BLOG_PAGE_CMS_REQUEST = "BLOG_PAGE_CMS_REQUEST";
export const BLOG_PAGE_CMS_SUCCESS = "BLOG_PAGE_CMS_SUCCESS";
export const BLOG_PAGE_CMS_FAILED = "BLOG_PAGE_CMS_FAILED";

export const BLOG_PAGE_REQUEST = "BLOG_PAGE_REQUEST";
export const BLOG_PAGE_SUCCESS = "BLOG_PAGE_SUCCESS";
export const BLOG_PAGE_FAILED = "BLOG_PAGE_FAILED";

export const BLOG_PAGE_GET_CATEGORIES_REQUEST =
  "BLOG_PAGE_GET_CATEGORIES_REQUEST";
export const BLOG_PAGE_GET_CATEGORIES_SUCCESS =
  "BLOG_PAGE_GET_CATEGORIES_SUCCESS";
export const BLOG_PAGE_GET_CATEGORIES_FAILED =
  "BLOG_PAGE_GET_CATEGORIES_FAILED";

export const BLOG_PAGE_GET_RECENT_POST_REQUEST =
  "BLOG_PAGE_GET_RECENT_POST_REQUEST";
export const BLOG_PAGE_GET_RECENT_POST_SUCCESS =
  "BLOG_PAGE_GET_RECENT_POST_SUCCESS";
export const BLOG_PAGE_GET_RECENT_POST_FAILED =
  "BLOG_PAGE_GET_RECENT_POST_FAILED";

export const BLOG_DETAIL_PAGE_REQUEST = "BLOG_DETAIL_PAGE_REQUEST";
export const BLOG_DETAIL_PAGE_SUCCESS = "BLOG_DETAIL_PAGE_SUCCESS";
export const BLOG_DETAIL_PAGE_FAILED = "BLOG_DETAIL_PAGE_FAILED";

export const SEARCH_BLOG_SUCCESS = "SEARCH_BLOG_SUCCESS";
export const SEARCH_BLOG_FAILED = "SEARCH_BLOG_FAILED";
