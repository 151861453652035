import { API_TOKEN, FLOWBOX_CHECKOUT_API_KEY } from "../Constants/Config";
import { ERROR, ERROR_LOGS, KEY_USER_TOKEN } from "../Constants/Labels";
import { store } from "../Redux/Store";
import { toast } from 'react-toastify';
import { GUEST_EMAIL } from "../Constants/LocalStorage";
import * as $ from 'jquery'
import { createLogs } from "../Services/axios.service";

export function isMobile(callback = null) {
    const mobileWidth = 767;
    // On window resize event
    if (callback && typeof callback !== 'undefined') {
        window.addEventListener('resize', () => {
            callback(window.outerWidth <= mobileWidth);
        });
    }
    return window.outerWidth <= mobileWidth;
}

export const Headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_TOKEN}`
}


export function setSessionItem(key, value) {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
}

export function clearSession(redirectTo) {
    const { dispatch } = store;
    localStorage.clear()
    // Update auth state
    dispatch({
        type: 'SAVE_AUTH',
        payload: false
    });
    // Clear customer cart
    dispatch({
        type: 'PURGE_CART'
    });
    // redirect
    if (typeof redirectTo !== 'undefined')
        window.location = redirectTo;
}

export function getSessionItem(key) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;;
}

export function removeSessionItem(key) {
    return localStorage.removeItem(key);;
}

export function isAuth() {
    const user = getSessionItem(KEY_USER_TOKEN);
    return user && typeof user !== 'undefined' ? user : false;
}

export function isGuestEmail() {
    const email = localStorage.getItem(GUEST_EMAIL);
    return email && typeof email !== 'undefined' ? email.replaceAll('"', '') : false;
}

export function showToast(type, messge) {
    toast[type](messge, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    scrollTop();
    if (type === ERROR) {
        createLogs({
            action: ERROR_LOGS.TOAST_ERROR,
            payment_method: null,
            api_response: JSON.stringify(messge),
            api_request: null,
            status: ERROR
        });
    }
}


export const scrollTop = () => {
    $('body,html').animate({ scrollTop: 0 }, 10);
}

export const getFaqFromSeoCode = (data, key) => {
    data = data?.[key];
    const faqs = [];
    data?.mainEntity.forEach((item) => {
        const faq = {}
        faq.question = item?.name;
        faq.answer = item?.acceptedAnswer?.text;
        faqs.push(faq);
    });
    return faqs;
}

export const loadExternalScript = (scriptUrl, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.readyState
        ? (script.onreadystatechange = function () {
            if ("loaded" === script.readyState || "complete" === script.readyState) {
                script.onreadystatechange = null;
                callback();
            }

        })
        : (script.onload = function () {
            callback();
        });
    script.src = scriptUrl;
    document.getElementsByTagName("head")[0].appendChild(script);
}

export const flowBoxCheckOutScript = (data) => {
    loadExternalScript("//connect.getflowbox.com/bzfy-checkout.js", function () {
        window.flowboxCheckout.checkout({
            apiKey: FLOWBOX_CHECKOUT_API_KEY,
            products: data?.products,
            orderId: data?.orderId,
            locale: "en-gb",
            customerData: data?.customerData,
        });
    });
}

export const loadFlowbox = () =>
    new Promise((resolve, reject) => {
        (function (d, id) {
            if (!window.flowbox) {
                let f = function () {
                    f.q.push(arguments);
                };
                f.q = [];
                window.flowbox = f;
            }
            if (d.getElementById(id)) {
                return resolve();
            }
            let s = d.createElement("script"),
                fjs = d.scripts[d.scripts.length - 1];
            s.id = id;
            s.async = true;
            s.src = "https://connect.getflowbox.com/flowbox.js";
            fjs.parentNode.insertBefore(s, fjs);
            resolve();
        })(document, "flowbox-js-embed");
    });

export const loadFlowboxReview = () =>
    new Promise((resolve, reject) => {
        (function (d, id) {
            if (!window.flowboxReviews) {
                let f = function () {
                    f.q.push(arguments);
                };
                f.q = [];
                window.flowboxReviews = f;
            }
            if (d.getElementById(id)) {
                return resolve();
            }
            let s = d.createElement("script"),
                fjs = d.scripts[d.scripts.length - 1];
            s.id = id;
            s.async = true;
            s.src = "https://reviews-widget.getflowbox.com/main.js";
            fjs.parentNode.insertBefore(s, fjs);
            resolve();
        })(document, "flowbox-js-review-embed");
    });