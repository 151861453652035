import Skeleton from "react-loading-skeleton";

const CategoryBlockSkl = (props: { count: number }) => {
  return (
    <div className="custom-container">
      <div className="category-list">
        {Array.from(Array(props.count)).map((data) => (
          <div className="item" key={data}>
          <div className="skl-img-section">
            <Skeleton />
          </div>
          <p className="ts cat-name">
            <Skeleton />
          </p>
        </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryBlockSkl;
