export const USER = "user";
export const CART_ID = "cartData";
export const GUEST_EMAIL = "g_email";
export const WEBSITE_ID = "storeId";
export const CURRENCY = "currency";
export const MEGA_MENU = "mega_menu";
export const COOKIE_ALLOW = "isCookieAllow";
export const PRODUCT_DETAILS_PREVIOUS_URL = "previous_url";
export const FIRST_NAME = "first_name";
export const LAST_NAME = "last_name";
