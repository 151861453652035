import { CART_ID } from './../Constants/LocalStorage';
import { LOGIN } from './../Constants/Route';
import { CURRENT_USER_UNAUTHORIZED } from './../Constants/Labels';
import axios from "axios";
import axiosRetry from "axios-retry";
import { KEY_USER_TOKEN, UN_AUTHORIZED_TEXT } from "../Constants/Labels";
import { getSessionItem, removeSessionItem } from "../Utility/Utilities";

// For GET requests

export function getToken() {
  return getSessionItem(KEY_USER_TOKEN);
}

axiosRetry(axios, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    // if retry condition is not specified, by default idempotent requests are retried
    return error?.response?.status === 503;
  },
});

axios.interceptors.request.use(
  function (config) {
    let accessToken = getToken();
    if (config.headers && !config.url?.includes(`https://pcls1.craftyclicks.co.uk`)) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
        Store: "salon",
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// For response
axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res && res.data && res.data.errors && res.data.errors[0]) {
      const message = res.data.errors[0]?.extensions?.category;
      if (res.data.errors[0]?.message === CURRENT_USER_UNAUTHORIZED) {
        removeSessionItem(KEY_USER_TOKEN)
         // Ticket id - 244 cart issue 
        removeSessionItem(CART_ID)
        window.location.href = LOGIN
        // store.dispatch({ type: UN_AUTH, payload: window.location.pathname }) 
        // IN PRODUCT DETAIL(GUEST USER):  second time (PRODUCT DETAIL TO LOGIN) then (CLICK BACK) and again click wishlist icon then unable to navigate to login, 
      }
      if (message === UN_AUTHORIZED_TEXT || message === CURRENT_USER_UNAUTHORIZED) {
        removeSessionItem(KEY_USER_TOKEN)
         // Ticket id - 244 cart issue 
        removeSessionItem(CART_ID)
        window.location.href = LOGIN
        // store.dispatch({ type: UN_AUTH, payload: window.location.pathname })
      }
    }
    return res;
  },
  (err) => {
    // Add configurations here
    return Promise.reject(err);
  }
);
