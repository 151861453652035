import Skeleton from "react-loading-skeleton";

const CardBannerSkl = (props: { count: number }) => {
  return (
    <div className="custom-container">
      <div className="cardrel-content">
        {
          Array.from(Array(2)).map((count) => (
            <div className="inner-card skl-content" key={count}>
              <div className="cr-image">
                <Skeleton />
              </div>
              <div className="desc-skl">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default CardBannerSkl;