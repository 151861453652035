import { GET_CMS_PAGE_FAILED, GET_CMS_PAGE_REQUEST, GET_CMS_PAGE_SUCCESS } from "./CMSPageTypes";

export function cmsResponse(state = {}, action: any) {
    switch (action.type) {
        case GET_CMS_PAGE_REQUEST:
            return { ...action.payload };
        case GET_CMS_PAGE_SUCCESS:
            return { ...action.payload };
        case GET_CMS_PAGE_FAILED:
            return { ...action.payload };
        default:
            return state;
    }
}