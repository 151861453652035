export const CHECKOUT_ADDRESS_REQUEST = "CHECKOUT_ADDRESS_REQUEST"
export const CHECKOUT_ADDRESS_SUCCESS = "CHECKOUT_ADDRESS_SUCCESS"
export const CHECKOUT_ADDRESS_FAILED = "CHECKOUT_ADDRESS_FAILED"

export const CHECKOUT_GET_DELIVERY_METHOD_REQUEST = "CHECKOUT_GET_DELIVERY_METHOD_REQUEST"
export const CHECKOUT_GET_DELIVERY_METHOD_SUCCESS = "CHECKOUT_GET_DELIVERY_METHOD_SUCCESS"
export const CHECKOUT_GET_DELIVERY_METHOD_FAILED = "CHECKOUT_GET_DELIVERY_METHOD_FAILED"

export const CHECKOUT_DELIVERY_METHOD_REQUEST = "CHECKOUT_DELIVERY_METHOD_REQUEST"
export const CHECKOUT_DELIVERY_METHOD_SUCCESS = "CHECKOUT_DELIVERY_METHOD_SUCCESS"
export const CHECKOUT_DELIVERY_METHOD_FAILED = "CHECKOUT_DELIVERY_METHOD_FAILED"

export const GET_BRAINTREETOKEN_REQUEST = "GET_BRAINTREETOKEN_REQUEST"
export const GET_BRAINTREETOKEN_SUCCESS = "GET_BRAINTREETOKEN_SUCCESS"
export const GET_BRAINTREETOKEN_FAILED = "GET_BRAINTREETOKEN_FAILED"

export const ORDER_PLACED_SUCCESS = "ORDER_PLACED_SUCCESS"
export const ORDER_PLACED_FAILED = "ORDER_PLACED_FAILED"

export const CHECK_EMAIL_AVAILABLE_SUCCESS = "CHECK_EMAIL_AVAILABLE_SUCCESS"
export const CHECK_EMAIL_AVAILABLE_FAILED = "CHECK_EMAIL_AVAILABLE_FAILED"

export const CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST = "CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST"
export const CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS = "CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS"
export const CREATE_PAYPAL_EXPRESS_TOKEN_FAILED = "CREATE_PAYPAL_EXPRESS_TOKEN_FAILED"

export const CREATE_RAPYD_CHECKOUT_ID_REQUEST = "CREATE_RAPYD_CHECKOUT_ID_REQUEST"
export const CREATE_RAPYD_CHECKOUT_ID_SUCCESS = "CREATE_RAPYD_CHECKOUT_ID_SUCCESS"
export const CREATE_RAPYD_CHECKOUT_ID_FAILED = "CREATE_RAPYD_CHECKOUT_ID_FAILED"

export const RAPYD_ORDER_SUCCESS = "RAPYD_ORDER_SUCCESS"
export const RAPYD_ORDER_FAILED = "RAPYD_ORDER_FAILED"

export const STRIPE_ORDER_REQUEST = "STRIPE_ORDER_REQUEST"
export const STRIPE_ORDER_SUCESS = "STRIPE_ORDER_SUCESS"
export const STRIPE_ORDER_FAILED = "STRIPE_ORDER_FAILED"
export const STRIPE_ORDER_CLEAR = "STRIPE_ORDER_CLEAR"
export const STRIPE_ORDER_AUTHENTICATION_REQUIRED = "STRIPE_ORDER_AUTHENTICATION_REQUIRED"

export const DIVIDEBUY_SPLASH_KEY_REQUEST = "DIVIDEBUY_SPLASH_KEY_REQUEST";
export const DIVIDEBUY_SPLASH_KEY_SUCCESS = "DIVIDEBUY_SPLASH_KEY_SUCCESS";
export const DIVIDEBUY_SPLASH_KEY_FAILED = "DIVIDEBUY_SPLASH_KEY_FAILED";
export const DIVIDEBUY_ORDER_SUCCESS = "DIVIDEBUY_ORDER_SUCCESS";

export const VERIFY_SPLASH_KEY_REQUEST = "VERIFY_SPLASH_KEY_REQUEST"
export const VERIFY_SPLASH_KEY_SUCCESS = "VERIFY_SPLASH_KEY_SUCCESS"
export const VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID = "VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID"
export const VERIFY_SPLASH_KEY_FAILED = "VERIFY_SPLASH_KEY_FAILED"


export const PAYMENT_INSTALLMENT_REQUEST = "PAYMENT_INSTALLMENT_REQUEST"
export const PAYMENT_INSTALLMENT_SUCCESS = "PAYMENT_INSTALLMENT_SUCCESS"
export const PAYMENT_INSTALLMENT_FAILED = "PAYMENT_INSTALLMENT_FAILED"

export const GET_PAYMENT_MIN_AMOUNT_REQUEST = "GET_PAYMENT_MIN_AMOUNT_REQUEST"
export const GET_PAYMENT_MIN_AMOUNT_SUCCESS = "GET_PAYMENT_MIN_AMOUNT_SUCCESS"
export const GET_PAYMENT_MIN_AMOUNT_FAILED = "GET_PAYMENT_MIN_AMOUNT_FAILED"
