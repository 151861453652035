import Skeleton from "react-loading-skeleton";

const SellingPointsSkl = () => {
  return (
    <div className="custom-container skl-points">
      <div className="point-list skl">
        <div className="sub-point-skl">
          <Skeleton className="for-img-skl" width={48} height={48} />
          <Skeleton className="for-text-skl" />
        </div>
        <div className="sub-point-skl">
          <Skeleton className="for-img-skl" width={48} height={48} />
          <Skeleton className="for-text-skl" />
        </div>
        <div className="sub-point-skl">
          <Skeleton className="for-img-skl" width={48} height={48} />
          <Skeleton className="for-text-skl" />
        </div>
      </div>
    </div>
  )
}

export default SellingPointsSkl;