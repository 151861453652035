import Skeleton from "react-loading-skeleton";

const BannerSectionSkl = () => {
  return (
    <>
      <div className="slider-item-skl">
        <Skeleton />
      </div>
    </>
  )
}

export default BannerSectionSkl;