import {
  BLOG_DETAIL_PAGE_FAILED,
  BLOG_DETAIL_PAGE_REQUEST,
  BLOG_DETAIL_PAGE_SUCCESS,
  BLOG_PAGE_CMS_FAILED,
  BLOG_PAGE_CMS_REQUEST,
  BLOG_PAGE_CMS_SUCCESS,
  BLOG_PAGE_FAILED,
  BLOG_PAGE_GET_CATEGORIES_FAILED,
  BLOG_PAGE_GET_CATEGORIES_REQUEST,
  BLOG_PAGE_GET_CATEGORIES_SUCCESS,
  BLOG_PAGE_GET_RECENT_POST_FAILED,
  BLOG_PAGE_GET_RECENT_POST_REQUEST,
  BLOG_PAGE_GET_RECENT_POST_SUCCESS,
  BLOG_PAGE_REQUEST,
  BLOG_PAGE_SUCCESS,
  SEARCH_BLOG_FAILED,
  SEARCH_BLOG_SUCCESS,
} from "./BlogPageTypes";

export function blogListResponse(state = {}, action: any) {
  switch (action.type) {
    case BLOG_PAGE_REQUEST:
      return { ...action.payload };
    case BLOG_PAGE_SUCCESS:
      return { ...action.payload };
    case BLOG_PAGE_FAILED:
      return { ...action.payload };
    case BLOG_PAGE_CMS_REQUEST:
      return { ...action.payload };
    case BLOG_PAGE_CMS_SUCCESS:
      return { ...action.payload };
    case BLOG_PAGE_CMS_FAILED:
      return { ...action.payload };
    case BLOG_PAGE_GET_CATEGORIES_REQUEST:
      return { ...action.payload };
    case BLOG_PAGE_GET_CATEGORIES_SUCCESS:
      return { ...action.payload };
    case BLOG_PAGE_GET_CATEGORIES_FAILED:
      return { ...action.payload };
    case BLOG_PAGE_GET_RECENT_POST_REQUEST:
      return { ...action.payload };
    case BLOG_PAGE_GET_RECENT_POST_SUCCESS:
      return { ...action.payload };
    case BLOG_PAGE_GET_RECENT_POST_FAILED:
      return { ...action.payload };
    case BLOG_DETAIL_PAGE_REQUEST:
      return { ...action.payload };
    case BLOG_DETAIL_PAGE_SUCCESS:
      return { ...action.payload };
    case BLOG_DETAIL_PAGE_FAILED:
      return { ...action.payload };
    case SEARCH_BLOG_FAILED:
      return { ...action.payload };
    case SEARCH_BLOG_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}
